import type { DeepPartial } from "~~/types/DeepPartial";
import type { Seo } from "~~/types/strapi/components/shared/interfaces/Seo";

export function useCustomHead(data?: DeepPartial<Seo> | null): void {
  if (data === undefined || data === null) {
    return;
  }

  const img = useImage();

  const title = data.metaTitle
    ? `${data.metaTitle} - haelsi`
    : "Dein Gesundheitszentrum in 1020 und 1180 Wien - haelsi";
  const description = data.metaDescription;
  const keywords = data.keywords;
  const robots = data.metaRobots;
  const image = data.metaImage?.data?.attributes;
  const ogImage =
    image && image.url
      ? {
          alt: image.alternativeText ?? undefined,
          height: image.height,
          url: img(image.url),
          width: image.width,
        }
      : undefined;

  useHead({
    meta: [{ content: description, name: "description" }],
    title,
  });

  useSeoMeta({
    description,
    keywords,
    ogDescription: description,
    ogImage,
    ogTitle: title,
    robots,
    title,
  });

  const canonicalURL = data.canonicalURL;
  if (canonicalURL) {
    useHead({
      link: [
        {
          href: canonicalURL,
          rel: "canonical",
        },
      ],
    });
    useSeoMeta({
      ogUrl: canonicalURL,
    });
  }
}
